@if (resourceQueueItems?.length !== 0 && resources) {
	<div
		class="main fx-layout-row fx-content-center fx-items-center fx-gap-24 fx-fill">
		@for (res of resourceQueueItems; track res) {
			<section class="resource fx-layout-column-nowrap">
				<section
					class="active-queue fx-layout-column fx-content-space-around fx-items-center">
					<div
						class="active-image fx-layout-row fx-content-center fx-items-center">
						@switch (res.queueItems[0]['patientSex']) {
							@case ('F') {
								<img [src]="'/upload-dir/woman.png'" alt="F" />
							}
							@case ('M') {
								<img [src]="'/upload-dir/man.png'" alt="M" />
							}
							@default {
								<img [src]="'/upload-dir/woman.png'" alt="O" />
							}
						}
					</div>
					<div
						class="fx-layout-column fx-content-center fx-items-center">
						<div class="active-patient-name">
							{{ res.queueItems[0]['patientName'] }}
						</div>
						<div class="active-ticket-number">
							{{ res.queueItems[0]['ticketNumber'] }}
						</div>
					</div>
				</section>

				<div class="resource-title">{{ res.resourceName }}</div>

				<section
					class="queue-items fx-layout-column fx-content-start fx-gap-16">
					@for (o of dropFirst(res.queueItems); track o) {
						<div
							class="fx-layout-row fx-content-start fx-items-center fx-gap-24">
							<div class="ticket-number">
								{{ o['ticketNumber'] }}
							</div>
							<div
								class="queue-image fx-layout-column fx-content-center fx-items-center">
								@switch (o['patientSex']) {
									@case ('F') {
										<img
											[src]="'/upload-dir/woman.png'"
											alt="F" />
									}
									@case ('M') {
										<img
											[src]="'/upload-dir/man.png'"
											alt="M" />
									}
									@default {
										<img
											[src]="'/upload-dir/woman.png'"
											alt="O" />
									}
								}
							</div>
							<div
								class="fx-layout-column fx-content-center fx-items-start">
								<div class="ft-patient-link">
									{{ o['patientName'] }}
								</div>
								<div class="appointment-time">
									{{ o['appointmentTime'] }}
								</div>
							</div>
						</div>
					}
				</section>
			</section>
		}
	</div>
}

<div class="circle1"></div>
<div class="circle2"></div>
