import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';
import { StringUtils } from '../../utils';
import { WsService } from '../../ws.service';
import { drop, map as _map, mapValues, reduce, take } from 'lodash';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { GeneralSettingDTO } from '../../model';
import { AppConfigService } from '../../app-config.service';

interface QueueItem {
	patientName: string;
	patientSex: string;
	examType: string;
	ticketNumber: string;
	appointmentTime: string;
	expectedDuration: number;
	performingPhysician: string;
	room: string;
	aet: string;
	modality: string;
}

@Component({
	selector: 'ft-queue',
	standalone: true,
	templateUrl: './queue.component.html',
	styleUrl: './queue.component.scss',
})
export class QueueComponent implements OnInit, OnDestroy {
	resourceQueueItems: { resourceName: string; queueItems: QueueItem[] }[] =
		[];
	resources: string[] = [];
	private sub$: Subscription;
	public generalSetting: GeneralSettingDTO;
	private queueItems: QueueItem[];

	constructor(
		private _config: AppConfigService,
		private shared: SharedService,
		private _ws: WsService
	) {
		this.generalSetting = this._config.generalSetting;
		setTimeout(
			() =>
				this._ws.observeTopic('workflow').subscribe(res => {
					if (res.response === 'update') {
						this.getQueueItems();
					}
				}),
			2000
		);
	}

	ngOnInit(): void {
		this.sub$ = this.shared.getQueueItems().subscribe(data => {
			this.queueItems = data;

			const resourceQueueItems = StringUtils.groupBy(
				this.queueItems,
				this.generalSetting.queueResource
			);
			this.resources = Object.keys(resourceQueueItems);

			const builtData = mapValues(resourceQueueItems, data =>
				this.buildData(data)
			);

			this.resourceQueueItems = _map(this.resources, res => {
				return { resourceName: res, queueItems: builtData[res] };
			});
		});
	}

	dropFirst = (array: any): any[] => drop(array);

	ngOnDestroy() {
		this.unsubscribe();
	}

	private getQueueItems(): void {
		this.shared.getQueueItems().subscribe(data => {
			const resourceQueueItems = StringUtils.groupBy(
				data,
				this.generalSetting.queueResource
			);
			this.resources = Object.keys(resourceQueueItems);

			const builtData = mapValues(resourceQueueItems, data =>
				this.buildData(data)
			);

			this.resourceQueueItems = _map(this.resources, res => {
				return { resourceName: res, queueItems: builtData[res] };
			});
		});
	}

	private unsubscribe() {
		if (this.sub$) this.sub$.unsubscribe();
	}

	private buildData(data: any): QueueItem[] {
		return data.map(it => {
			const first = data && data.length != 0 ? data[0] : null;

			const idx = data.findIndex(v => v.ticketNumber === it.ticketNumber);
			const previous = data[idx - 1];

			const previousData = take(data, idx);

			if (previousData.length != 0) {
				const durationShift = reduce(
					_map(previousData, 'expectedDuration'),
					(sum, a) => sum + a,
					0
				);

				if (
					first &&
					moment(first.appointmentTime, 'HH:mm')
						.add(durationShift, 'minutes')
						.isAfter(moment(), 'm')
				) {
					it.appointmentTime = moment(first.appointmentTime, 'HH:mm')
						.add(durationShift, 'minutes')
						.format('HH:mm');
				} else if (
					moment(it.appointmentTime, 'HH:mm')
						.subtract(it.expectedDuration, 'minutes')
						.isBefore(
							moment(previous.appointmentTime, 'HH:mm'),
							'm'
						)
				) {
					it.appointmentTime = moment(it.appointmentTime, 'HH:mm')
						.add(previous.expectedDuration, 'minutes')
						.format('HH:mm');
				}
			}
			return it;
		});
	}
}
